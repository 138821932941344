'use strict'; // Load jQuery

import jQuery from 'jquery';
window.jQuery = window.$ = jQuery; // Load Bootstrap 5

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap; // Load Moment.js - Parse, validate, manipulate and display dates and times

import moment from 'moment';
window.moment = moment; // Load Flatpickr - datetime picker

import flatpickr from 'flatpickr';
jQuery.fn.extend({
  flatpickr: function (opt) {
    flatpickr(this, opt);
  }
});
window.flatpickr = flatpickr; // Load Select2 - replacement for select boxes

import select2 from "select2/dist/js/select2.full";
select2(jQuery); // Load Sortable - Reorderable drag-and-drop lists

import Sortable from "sortablejs";
jQuery.fn.extend({
  sortable: function (opt) {
    flatpickr(this, opt);
  }
});
window.Sortable = Sortable; // Load Spectrum - The No Hassle jQuery Colorpicker

const spectrum = require('spectrum-colorpicker2/dist/spectrum.js');

window.spectrum = spectrum; // Load Tagify - tags input component

import Tagify from '@yaireo/tagify';
window.Tagify = Tagify;
jQuery.fn.extend({
  tagify: function (opt) {
    return new Tagify(this, opt);
  }
});
document.addEventListener('DOMContentLoaded', function () {
  // Connect menu button with aside-menu
  jQuery('#aside-menu').on('show.bs.collapse', function () {
    jQuery('body').addClass('menu-opened');
  }).on('hide.bs.collapse', function () {
    jQuery('body').removeClass('menu-opened');
  });
});